import React, { useState } from 'react';
import * as d3 from 'd3';
import * as R from 'ramda';

const containsMonthlyDataKey = function(myObject) {
  return R.contains('monthlyData', R.keys(myObject));
};

const doAllObjectsContainMonthlyDataKey = R.compose(
  R.all(R.equals(true)),
  R.map(containsMonthlyDataKey),
);

const doesMonthlyDataContainArray = R.compose(
  R.all(R.equals(true)),
  R.map(R.is(Array)),
  R.map((d) => d['monthlyData']),
);

const areAllArrayElementsObjects = R.compose(
  R.all(R.equals(true)),
  R.map(R.is(Object)),
);

export const validateGraphDataStructure = function (plotDataArray) {
  if (!plotDataArray) return 'There is no data to plot.  The monthly data is undefined.';

  if (!Array.isArray(plotDataArray)) return `An array of monthly data objects is expected.
	  However, the value provided was not an array.  The following plotDataArray was provided:
    ${JSON.stringify(plotDataArray).substring(0, 100)}...`;

	if (plotDataArray.length === 0) return 'There is no data to plot. The array of monthly data is empty.';

  // Check to see if the array elements are all objects
  if (!areAllArrayElementsObjects(plotDataArray)) return `An array of monthly data objects is expected.
		However, at least one of array items is not a Javascript Object.
		The following plotDataArray was provided:  ${JSON.stringify(plotDataArray).substring(0, 100)}...`;

  // Check to see if all of the objects contain keys called monthyData
  if ( !doAllObjectsContainMonthlyDataKey(plotDataArray)) return `An array of monthly data objects is
		expected. Each object should contain a key named 'monthlyData' with a value containing an array
		of monthly data.  At least one of the objects is lacking a key named 'monthlyData'.
		The following plotDataArray was provided:  ${JSON.stringify(plotDataArray).substring(0, 100)}...`;

  // Check to see if all of the monthlyData values are arrays
	if ( !doesMonthlyDataContainArray(plotDataArray)) return `An array of monthly data objects is
		expected. Each object should contain a key named 'monthlyData' with a value set to an array of
		monthly data.  At least one of the values is not an array.  The following plotDataArray was provided:
		${JSON.stringify(plotDataArray).substring(0, 100)}...`;

  return null;
};

export const getMaxMonthlyDataLength = R.compose(
  R.reduce(R.max, 0),
  R.map(R.length),
  R.map((d) => d.monthlyData),
);

export const getMaxMonthlyDataValue = R.compose(
  R.ifElse(R.equals(-Infinity), R.always(undefined), R.identity),
  R.reduce(R.max, -Infinity),
  R.map(R.reduce(R.max, -Infinity)),
  R.map((d) => d.monthlyData),
);

export const getMinMonthlyDataValue = R.compose(
  R.ifElse(R.equals(Infinity), R.always(undefined), R.identity),
  R.reduce(R.min, Infinity),
  R.map(R.reduce(R.min, Infinity)),
  R.map((d) => d.monthlyData),
);

/**
 * Returns the minimum of either the min actual data value or zero.
 *
 * For cartesian plots, I typically desire the scale to start at zero (not start at the minimum
 * data value, which is often a positive number).  Hence, I created this function with this
 * need in mind.
 *
 * Keith Elliott, 6/17/2020
 */
export const getMinMonthlyDataValueOrZero = R.compose(
  R.min(0),
  getMinMonthlyDataValue
)

export const getMinPositiveMonthlyDataValue = R.compose(
  R.ifElse(R.equals(Infinity), R.always(undefined), R.identity),
  R.reduce(R.min, Infinity),
  R.map(R.reduce(R.min, Infinity)),
  R.map(R.filter((d) => d > 0)),
  R.map((d) => d.monthlyData),
);

export const roundUpToPowerOfTen = function (x) {
  return Math.pow(10, Math.ceil(Math.log10(x)));
};

export const roundDownToPowerOfTen = function(x) {
  return Math.pow(10, Math.floor(Math.log10(x)));
};

export const areAllMonthlyValuesGteZero = R.compose(
  R.all(R.equals(0)),
  R.map(R.length()),
  R.map(R.filter((d) => d < 0)),
  R.map((d) => d.monthlyData),
);

export const validateMonthlyDataValues = function(yAxisType, monthlyData) {
 	if (yAxisType === 'log' && !areAllMonthlyValuesGteZero(monthlyData))
    return `Line Graph Warning:  Negative monthly data exists.
      For a logarithmic Y axis, only positive data values are plotted.
      The negative and zero values are filtered out of the plot.`;

  return null;
};

// monthlyData -> Number
// monthlyData is an array tha contains objects.  Each object holds a property named monthlyData,
// with its value set to an array of monthly production volumes.
export const calcMaxLogScaleValue = R.compose(
  roundUpToPowerOfTen,
  getMaxMonthlyDataValue,
);

/**
 * Returns an appropriate minimum log scale value.
 *
 * Zero values may exist in the monthly data.  These zero values are filtered-out, and not
 * included in the determination of the minimum value.
 *
 * I expect that the plotDataArray will be passed to this function.
 *
 * plotDataArray is an array that contains objects.  Each object holds a property named monthlyData,
 * with its value set to an array of monthly production volumes.
 * @sig plotDataArray -> Number
 * @author Keith Elliott, 6/11/2020
 */
export const calcMinLogScaleValue = R.compose(
  roundDownToPowerOfTen,
  getMinPositiveMonthlyDataValue,
);

/**
 * Returns a sum of all of the monthlyData arrays that are in the provided plotDataArray.
 *
 * I use this when comparing two plotDataArrays.  I assume that if the sum of all the monthlyData
 * arrays are equal, then the arrays are equal.
 *
 * @sig plotDataArray -> Number
 * @author Keith Elliott, 6/15/2020
 */
export const sumAllMonthlyData = R.compose(
  R.sum(),
  R.flatten(),
  R.map(d => d.monthlyData),
  R.ifElse(R.equals(undefined), R.always([]), R.identity)
);

export const isPlotDataArrayEqual = (plotDataArray1, plotDataArray2) => (
  sumAllMonthlyData(plotDataArray1) === sumAllMonthlyData(plotDataArray2)
);

/**
 * @param isMonthly {Boolean} true for monthly x scale, false (the default) for yearly x scale.
 * Note that the monthly scale is one-based (the first month in the array is shown in the x scale
 * position of one, not zero.)  In contrast, the yearly scale is zero based (the first month in the
 * array is shown in the s scale position of zero, not one).   I chose this behavior because we
 * commonly think of month one as January (e.g., we don't think of months that have a number
 * of zero).  In contrast, years are naturally zero-based (e.g. when we are at a year value of 1, we
 * expect to be in the beginning of the next year).
 * @author Keith Elliott, 9/25/2020
 */
function plotProdForecastLine(myRef, plotDataArray, myWidth, myHeight, xAxisLabel,
  yAxisLabel, yAxisType = 'cartesian', title, subTitle, showLegend = true, isMonthly = false) {

  const validationMessageDataStructure = validateGraphDataStructure(plotDataArray);
  if (validationMessageDataStructure) {

    d3.select(myRef)
      .append("p")
      .text(validationMessageDataStructure);

    // Return and stop the function.  Cannot proceed with incorrect data structure.
    return validationMessageDataStructure;
  }

  const validationMessageDataValues = validateMonthlyDataValues(yAxisType, plotDataArray);
  if (validationMessageDataValues) {

    d3.select(myRef)
      .append("p")
      .text(validationMessageDataValues);

    // Continue with the function.  Warning is noted.  But, function can proceed
    // because negative numbers will be filtered-out.
  }

	// Dimensions
  const margin = { top: 80, right: 40, bottom: 20, left: 80 };
  const width = myWidth - margin.right - margin.left;
  const height = myHeight - margin.top - margin.bottom;
  const legendMarkerWidth = 30;
  const legendMarkerHeight = 15;
  const legendRowSpacing = 20;
  const legendBackgroundWidth = 180;
  const legendBackgroundHeight = legendRowSpacing;
  const labelYshift = 95;

  const MIN_PROD_RATE = 1;

  // Scale
  const xScale = isMonthly
    ? d3.scaleLinear()
      .domain([0, (getMaxMonthlyDataLength(plotDataArray) + 1)]) // one-based, so add one to
      .range([0, width])
    : d3.scaleLinear()
      .domain([0, ((getMaxMonthlyDataLength(plotDataArray) -1) / 12)]) // Convert from monthly to yearly
      .range([0, width]);

  let yScale = yAxisType === 'log'
    ? d3.scaleLog()
      .domain([calcMinLogScaleValue(plotDataArray), calcMaxLogScaleValue(plotDataArray) ])
      .range([height, 0])
    : d3.scaleLinear()
      .domain([getMinMonthlyDataValue(plotDataArray), getMaxMonthlyDataValue(plotDataArray) ])
      .range([height, 0]);

  // Draw base
  const svg = d3.select(myRef)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.right})`); // KTE, 10/6/2020:  Ahhh!  Bug. .right should be .top.  I'll fix later when I can take time to refactor

  // Draw x axis
  const xAxis = d3.axisBottom(xScale)
    .tickSizeInner(-height)

  const xAxisDraw = svg
    .append("g")
    .attr("transform", `translate(0, ${height})`)
    .attr("class", "x axis")
    .call(xAxis);

  // Draw X Axis Label
  svg
    .append("g")
    .attr("class", "x axisLabel")
    .attr("transform", `translate(${width / 2}, ${height})`)
    .append("text")
    .attr("text-anchor", "middle")
    .text(xAxisLabel)
    .attr('y', '2em');

  // Draw y axis
  const yAxis = d3.axisLeft(yScale)
    .tickSizeInner(-width)
    .tickFormat(d3.format(",.2r"));

  if (yAxisType === 'log') {
    // Limit labeled log scale to the major powers of 10
    yAxis.ticks(3);
  }

  const yAxisDraw = svg.append("g")
    .attr("class", "y axis")
    .attr("transform", `translate(0,0)`)
    .call(yAxis);

  // Draw Y Axis Label
  svg
    .append("g")
    .attr("class", "y axisLabel")
    /*.attr("transform", `translate(${-margin.left * 1.4}, ${height/2})`)*/
    .attr("transform", `translate(${-labelYshift}, ${height/2})`)
    .append("text")
    .attr("text-anchor", "middle")
    .text(yAxisLabel)
    .attr('y', '2em')
    .attr('transform', 'rotate(-90)');

  // If Y axis is log scale, then draw additional minor scales (above I limit the main
  // log scale to 5 ticks)
  if (yAxisType === 'log') {
    const yAxisMinor = d3.axisLeft(yScale)
      .tickSizeInner(-width)
      .tickFormat("");

    const yAxisDraw = svg.append("g")
      .attr("class", "y axis minor")
      .attr("transform", `translate(0,0)`)
      .call(yAxisMinor);
  }


  // Draw title
  const header = svg
    .append("g")
    .attr("class", "plot-title")  // KTE, 8/1/2020:  changed class name from header
    .attr("transform", `translate(0, ${-margin.top * 0.3})`)
    .append("text")
    .attr("text-anchor", "start");

  const headerMain = header.append("tspan").attr('class', 'plot-main-title').text(title);

  // Draw subtitle
  const headerSub = header
    .append("tspan")
    .attr('class', 'plot-sub-title')
    .text(subTitle)
    .attr("x", 0)
    .attr("y", '1.3em');

  // Line generator
  // A single array containing monthly values is expected
  const line = d3.line()
    .x((d, i) => isMonthly ? xScale(i + 1) : xScale(i / 12)) // Divide by 12 to convert from months to years
    .y((d) => yScale(d))

  if (yAxisType === 'log') {
    // Filter out zero values, which do not jive with logarithmic scales
    line.defined((d) => d > 0);
  }

  // Draw Line
  const chartGroup = svg
    .append('g')
    .attr('class', 'line-chart');

  chartGroup
    .selectAll('line-series')
    .data(plotDataArray)
    .enter()
    .append('path')
    .attr('d', d => line(d.monthlyData))
    .attr('class', d => d.name)
    .attr('fill', 'none')
    .attr('class', d => d.nameCssClass)


  if (showLegend) {

    const legend = svg
      .append('g')
      .attr('class', 'legend')
      .attr("transform", `translate(${width * .82},0)`);

    legend
      .selectAll('legendBackground')
      .data(plotDataArray)
      .enter()
      .append('rect')
      .attr('x', 0)
      .attr('y', (d, i) => i * legendRowSpacing)
      .attr('width', legendBackgroundWidth)
      .attr('height', legendBackgroundHeight)
      .attr('class', (d) => d.nameCssClass + ' background');

    legend
      .selectAll('legendItem')
      .data(plotDataArray)
      .enter()
      .append('rect')
      .attr('x', 0)
      .attr('y', (d, i) => i * legendRowSpacing)
      .attr('width', legendMarkerWidth)
      .attr('height', legendMarkerHeight)
      .attr('class', (d) => d.nameCssClass);

    legend
      .selectAll('legendText')
      .data(plotDataArray)
      .enter()
      .append('text')
      .attr('x', legendMarkerWidth + 5)
      .attr('y', (d, i) => i * legendRowSpacing)
      .attr('class', (d) => d.nameCssClass)
      .style('alignment-baseline', 'hanging')
      .text((d) => d.name);
  }
}

class PlotLineGraph extends React.Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    plotProdForecastLine(this.myRef.current,
      this.props.plotDataArray,
      this.props.width,
      this.props.height,
      this.props.xAxisLabel,
      this.props.yAxisLabel,
      this.props.yAxisType,
      this.props.title,
      this.props.subTitle,
      this.props.showLegend,
      this.props.isMonthly,
    );
  }

  componentDidUpdate(prevProps) {
    if ( ! isPlotDataArrayEqual(prevProps.plotDataArray, this.props.plotDataArray) ) {
      d3.select(this.myRef.current).select("svg").remove();

      plotProdForecastLine(this.myRef.current,
        this.props.plotDataArray,
        this.props.width,
        this.props.height,
        this.props.xAxisLabel,
        this.props.yAxisLabel,
        this.props.yAxisType,
        this.props.title,
        this.props.subTitle,
        this.props.showLegend,
        this.props.isMonthly,
      );
    }
  }

  render() {
    return (
      <div className="my-plot" ref={this.myRef} />
    );
  }
}

export default PlotLineGraph;
